import React, { Component } from "react";
import debounce from "lodash.debounce";
import img from './ImageCollection.png'

export default class extends Component {
  constructor(props) {
    super(props);
    this.handleResize = debounce(this.handleResize.bind(this), 400);
  }

  handleResize() {
    this.setState(this.state);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div className="ImageCollection">
        <div>
          <img src={img} alt="" />
        </div>
      </div>
    );
  }
}
