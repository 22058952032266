import React from "react";
import { EditHighlightsSection } from "@zopsmart/zs-components";
const FeatureLayoutWrp = () => {
  return {
    fields: (props) => {
      return (
        <EditHighlightsSection component={EditHighlightsSection} {...props} />
      );
    },
  };
};
export default FeatureLayoutWrp;
