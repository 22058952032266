import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import InfluencerForm from "./Form";
import { Row, Cell } from "../../../components/Table";
import { DropDown, DropDownItem, ICONS } from "../../../components/DropDown";
import { Dialog } from "../../../components/Popup";
import { getMessage } from "../../../lib/translator";
import API from "../../../lib/api";
import { get } from "../../../lib/storage";

import "./style.css";
import emptyIcon from "./influencer-empty.svg";

class InfluencerActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAction: null,
    };
    this.showStatusActionDialog = this.showStatusActionDialog.bind(this);
    this.hideStatusActionDialog = this.hideStatusActionDialog.bind(this);
  }
  showStatusActionDialog(statusAction) {
    this.setState({ statusAction });
  }
  hideStatusActionDialog() {
    this.setState({ statusAction: false });
  }
  setPublishStatus(status) {
    let id = this.props.id;
    let api = new API({ url: `/recipe-service/influencer/${id}` });
    return api
      .put({ status })
      .then((response) => {
        this.props.onAction(TABLE_ACTIONS.EDIT, { id }, { status });
        this.hideStatusActionDialog();
      }, console.error)
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    let { id, status } = this.props;
    const isEnabled = status === "ENABLED";
    return (
      <div>
        <Dialog
          show={this.state.statusAction}
          title={getMessage("are.you.sure")}
          information={
            status === "HIDDEN"
              ? getMessage("marketing.influencer.dialogs.enable.message")
              : getMessage("marketing.influencer.dialogs.hide.message")
          }
          onOk={() => {
            isEnabled
              ? this.setPublishStatus("HIDDEN")
              : this.setPublishStatus("ENABLED");
          }}
          close={this.hideStatusActionDialog}
          closeText={getMessage("cancel.text")}
          okText={
            isEnabled
              ? getMessage("marketing.influencer.table.action.hide")
              : getMessage("marketing.influencer.table.action.enable")
          }
        />
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/marketing/influencer/edit/${id}`}>
              {getMessage("marketing.action.edit.text")}
            </Link>
          </DropDownItem>
          <DropDownItem>
            <div
              onClick={() => {
                this.showStatusActionDialog(id);
              }}
            >
              {isEnabled
                ? getMessage("marketing.influencer.table.action.hide")
                : getMessage("marketing.influencer.table.action.enable")}
            </div>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              this.props.onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("delete.text")}
          </DropDownItem>
        </DropDown>
      </div>
    );
  }
}

function AddInfluencer() {
  return (
    <div className="text-center">
      <Link to="/marketing/influencer/add" className="primary button">
        <span>+ </span>
        <span className="text">
          {getMessage("marketing.influencer.add.text")}
        </span>
      </Link>
    </div>
  );
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage("marketing.influencer.helpItems.message"),
  additionalViews: [AddInfluencer],
};

const tableProperties = {
  headers: [
    getMessage("marketing.influencer.name"),
    getMessage("marketing.influencer.description"),
    getMessage("marketing.influencer.status"),
    getMessage("marketing.influencer.action.text"),
  ],
  row: ({ id, name, isDraft, onAction, description, status }) => (
    <Row>
      <Cell className="influencer-page-name">
        <Link
          to={`/marketing/influencer/edit/${id}`}
          className="influencer-title-link"
        >
          {name}
        </Link>
      </Cell>
      <Cell className="influencer-page-description">
        {description ? description : null}
      </Cell>
      <Cell className="influencer-page-published">
        <small className={`status-${status.toLowerCase()}`}>{status}</small>
      </Cell>
      <Cell className="influencer-page-actions">
        <InfluencerActions onAction={onAction} id={id} status={status} />
      </Cell>
    </Row>
  ),
};

export default function Influencer(props) {
  return (
    <ListingPageWithRoutes
      helmetTitle="Zopping - Influencers"
      menu={props.menu}
      className="influencer-page"
      title="Influencers"
      addHeading={getMessage("marketing.influencer.form.add.heading")}
      editHeading={getMessage("marketing.influencer.form.edit.heading")}
      emptyState={emptyState}
      headerActions={AddInfluencer}
      api={{
        url: "/recipe-service/influencer",
        headers: {
          "Accept-Language": get("dataLang") || "en",
        },
        transform: (response) => response.data?.influencer || [],
      }}
      showLanguageDropDown
      form={{
        component: InfluencerForm,
        transformSubmit: (formData) => {
          if (Array.isArray(formData?.metaData)) {
            // Filtering out entries with missing socialMediaId
            let validMetaData = formData?.metaData.filter(
              ({ socialMediaType, socialMediaId }) =>
                socialMediaType && socialMediaId
            );

            // Converting metadata into key-value format
            let transformedMetaData = validMetaData.reduce(
              (acc, { socialMediaType, socialMediaId }) => {
                acc[socialMediaType] = socialMediaId;
                return acc;
              },
              {}
            );
            // Extracting valid social media types
            let validTypes = new Set(Object.keys(transformedMetaData));

            // Reseting default if it no longer exists in valid metadata
            if (!validTypes.has(formData.default)) {
              formData.default = null;
            }

            // Converting transformedMetaData back into an array of objects
            let finalMetaData = Object.entries(transformedMetaData).map(
              ([key, value]) => ({ [key]: value })
            );

            // Ensuring default is at the top
            if (formData.default) {
              finalMetaData.unshift({ default: formData.default });
            }

            // Assigning corrected metadata back
            formData.metaData = finalMetaData;
          }
          formData.recipeIds = formData.recipes?.map((recipe) => recipe.id);
          delete formData.recipes;
          return formData;
        },
        transformResponse: (response) => {
          const transformedResponse = response?.data?.influencer || {};

          if (Array.isArray(transformedResponse?.metaData)) {
            let defaultValue = null;

            // Converting back to original format
            const restoredMetaData = transformedResponse?.metaData
              .map((item) => {
                const [key, value] = Object.entries(item)[0];

                if (key === "default") {
                  defaultValue = value; // Storing default separately
                  return null; // Remove it from metadata array
                }

                return { socialMediaType: key, socialMediaId: value };
              })
              .filter(Boolean); // Remove null entries (which were the default value)

            // Assigning default separately
            if (defaultValue) {
              transformedResponse.default = defaultValue;
            }

            transformedResponse.metaData = restoredMetaData;
          }
          return transformedResponse;
        },
      }}
      tableProperties={tableProperties}
    />
  );
}
