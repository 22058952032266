import React, { Component } from "react";
import Table, { Header, Row, Cell } from "../../../../../components/Table";
import { getMessage } from "../../../../../lib/translator";

function getMobileView() {
  return window.screen.width <= 480;
}

class RefundDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: getMobileView(),
    };
    this.mobileView = this.mobileView.bind(this);
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.mobileView, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.mobileView, false);
  }
  render() {
    let { isMobileView } = this.state;
    return (
      <div className="payment-details">
        <div className="section-title">
          <h3>{getMessage("Refund Details")}</h3>
          <Table>
            <Header>
              <Cell>{getMessage("order.details.payment.amount")}</Cell>
              <Cell>{getMessage("order.details.payment.mode")}</Cell>
              <Cell>{getMessage("order.details.payment.status")}</Cell>
              <Cell>{getMessage("order.details.payment.details")}</Cell>
            </Header>
            {this.props.refund.map((item, i) => (
              <Row key={`payment-${i}`} className="refund-row">
                <Cell className="payment-amount">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.amount")}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.props.currency.symbol} {item.amount}
                </Cell>
                <Cell className="payment-mode">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.mode")}
                    </div>
                  ) : (
                    ""
                  )}
                  <div>{item.method}</div>
                </Cell>
                <Cell className="payment-status">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.status")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.status || "COMPLETED"}
                </Cell>
                <Cell className="payment-transaction-id">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.details")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.refundReference && (
                    <span className="transactionId">
                      {getMessage("order.details.payment.ref")} &ndash;{" "}
                      {item.refundReference}
                    </span>
                  )}
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default RefundDetails;
