import ImageSlideShow from "./ImageSlideShow";
import ImageWithTextLayout from "./ImageWithText";
import ProductCollectionLayout from "./ProductCollection";
import BannerWithTextLayout from "./BannerWithText";
import BannerWithMultipleButtons from "./BannerWithMultipleButtons";
import VideoCarouselLayout from "./VideoCarousel";
import CategoryCollection from "./CategoryCollection";
import BrandCollection from "./BrandCollection";
import SubCategoryCollection from "./SubCategoryCollection";
import DualImageBanner from "./DualImageBanner";
import "./style.css";
import AppointmentFormLayout from "./AppointmentForm";
import DualImageLayout from "./DualImageGrid";
import BlogCollection from "./BlogCollection";
import TestimonialLayout from "./TestimonialLayout";
import CarouselLayout from "./CarouselLayout";
import RichTextLayout from "./RichTextLayout";
import SplashScreen from "./SplashScreen/SplashScreen";
import SellerCollection from "./SellerCollection";
import GridCollection from "./GridCollection";
import InfluencerCollection from "./InfluencerCollection";
import Footer from "./Footer";
import ImageCollection from "./ImageCollection";
import Highlights from "./Highlights";

/**
 *Object of all layouts and their respective jsx components
 */
const layouts = {
  ImageSlideShow: ImageSlideShow,
  ImageWithText: ImageWithTextLayout,
  ProductCollection: ProductCollectionLayout,
  BannerWithText: BannerWithTextLayout,
  BannerWithButton: BannerWithTextLayout,
  BannerWithMultipleButtons: BannerWithMultipleButtons,
  VideoCardCarousel: VideoCarouselLayout,
  CategoryCollection: CategoryCollection,
  BrandCollection: BrandCollection,
  SearchResults: ProductCollectionLayout,
  SubCategoryCollection: SubCategoryCollection,
  AppointmentForm: AppointmentFormLayout,
  DualImageBanner: DualImageBanner,
  DualImage: DualImageLayout,
  RichText: RichTextLayout,
  BlogCollection: BlogCollection,
  Testimonial: TestimonialLayout,
  Carousel: CarouselLayout,
  FooterLayout: Footer,
  Splash: SplashScreen,
  SellerCollection: SellerCollection,
  GridCollection: GridCollection,
  InfluencerCollection,
  ImageCollection,
  Highlights,
};

/**
 * @param {*} pageIndex
 * @param {*} layoutName
 * @param {*} pagesArray
 */
export const getLayout = (pageIndex, layoutName, pagesArray, selectOptions) => {
  const defaultOption =
    pagesArray[pageIndex] !== "home" && pagesArray[pageIndex] !== "search"
      ? "CURRENT"
      : "ALL";
  if (layoutName === "ProductCollection" || layoutName === "SearchResults") {
    return layouts[layoutName](selectOptions, defaultOption);
  } else {
    return layouts[layoutName]();
  }
};
export default getLayout;
