import React from "react";
import Tree from "../../../../components/Tree";
import Loader from "../../../../components/Loader";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import API from "../../../../lib/api";
import { getMessage } from "../../../../lib/translator";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import AddForm from "./AddForm";
import "./style.css";
import { Popup } from "../../../../components/Popup";
import Image from "../../../../components/Image";
import TagIcon from "./Tag-icon.svg";
import LinkIcon from "./Link-icon.svg";
import { get } from "../../../../lib/storage";

function recursivelyTraversing(data, path, index) {
  if (path.length <= index) {
    return data;
  } else {
    let key = path[index];
    return data.filter((item) => {
      if (item.index === key) {
        if (item.hasOwnProperty("children")) {
          if (path.length > index + 1) {
            item.children = recursivelyTraversing(item.children, path, ++index);
            item.menu = item.children;
            return item;
          }
        }
        return null;
      } else {
        return item;
      }
    });
  }
}

function generateSubMenu(data, submit = false, counter = 0) {
  data.map((item, index) => {
    item.index = index.toString() + "," + counter.toString();
    if (!item.menu) {
      if (item.id) {
        // Either category or tag
        if (item.url && item.url.includes("category")) {
          item["c"] = item.id;
        }
        if (item.url && item.url.includes("tag")) {
          item["t"] = item.id;
        }
      }
    } else {
      if (item.id) {
        // Either category or tag
        if (item.url && item.url.includes("category")) {
          item["c"] = item.id;
        }
        if (item.url && item.url.includes("tag")) {
          item["t"] = item.id;
        }
      }
      item[submit ? "menu" : "children"] = generateSubMenu(
        submit ? item.children : item.menu,
        submit,
        item.index
      );
    }
    if (!submit) {
      item.title = item.name;
      item.link = item.url;
      delete item.name;
      delete item.url;
    } else {
      item.name = item.title;
      if (item.hasOwnProperty("c")) {
        item.c = item.id;
        delete item.name;
        delete item.link;
      }

      if (item.hasOwnProperty("t")) {
        item.t = item.id;
        delete item.name;
        delete item.link;
      }

      delete item.children;
      delete item.id;
      delete item.url;
      delete item.title;
      delete item.index;
    }

    return item;
  });

  return data;
}

function findingNode(data, path, index) {
  if (path.length <= index) {
    return data;
  } else {
    if (index !== 0) {
      data = data.children;
    }
  }
  let key = path[index];
  let returnedObject = null;
  data.map((item) => {
    if (item.index === key) {
      returnedObject = findingNode(item, path, ++index);
    }
    return null;
  });
  return returnedObject;
}
class CategorySequencing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      treeData: [],
      showPopup: false,
    };

    this.removeItem = this.removeItem.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.removeItemFromList = this.removeItemFromList.bind(this);
    this.onAppendMethod = this.onAppendMethod.bind(this);
    this.onAppendClick = this.onAppendClick.bind(this);
    this.headers= { "Accept-Language": get("dataLang") || "en" }
  }

  onAppendClick(type) {
    this.setState({
      showPopup: true,
      append: true,
      val: type,
    });
  }

  onAppendMethod() {
    let treeData = JSON.parse(JSON.stringify(this.state.treeData));
    if (this.state.val === "Link") {
      treeData.push({
        title: this.state.data.linkText,
        link: this.state.data.href,
        index: this.state.treeData.length,
      });
    } else {
      let tagId = (this.state.data.tag && this.state.data.tag.id) || null;
      let tagName = (this.state.data.tag && this.state.data.tag.name) || null;
      treeData.push({
        t: tagId,
        id: tagId,
        title: tagName,
        index: this.state.treeData.length,
      });
    }
    this.setState({
      treeData,
      onSubmit: true,
      append: false,
    });
  }

  removeItemFromList(list, key) {
    return list.filter((item) => {
      if (item.index !== key) {
        return item;
      }
      return null;
    });
  }
  removeItem(path) {
    let treeData = JSON.parse(JSON.stringify(this.state.treeData));
    let data = recursivelyTraversing(treeData, path, 0);
    this.setState({
      treeData: data,
      onSubmit: true,
    });
  }

  async onConfirmClick(data) {
    await this.setState({
      data,
    });

    this.state.append ? await this.onAppendMethod() : await this.onAddClick();
    this.hidePopup();
  }

  showPopup(path, type) {
    this.setState({
      showPopup: true,
      path,
      val: type,
    });
  }

  hidePopup() {
    this.setState({
      showPopup: false,
    });
  }

  onAddClick() {
    let treeData = JSON.parse(JSON.stringify(this.state.treeData));
    let item = findingNode(treeData, this.state.path, 0);
    if (item) {
      if (this.state.val === "Link") {
        if (item.hasOwnProperty("children")) {
          item.children.push({
            title: this.state.data.linkText,
            link: this.state.data.href,
            index: item.children.length + "," + item.index,
          });
          item.menu = item.children;
        } else {
          item.children = [];
          item.children.push({
            title: this.state.data.linkText,
            link: this.state.data.href,
            index: item.children.length + "," + item.index,
          });
          item.menu = item.children;
        }
      } else {
        let tagId = (this.state.data.tag && this.state.data.tag.id) || null;
        let tagName = (this.state.data.tag && this.state.data.tag.name) || null;
        if (this.state.val === "Tag" && tagId && tagName) {
          if (item.hasOwnProperty("children")) {
            item.children.push({
              t: tagId,
              id: tagId,
              title: tagName,
              index: item.children.length + "," + item.index,
            });
            item.menu = item.children;
          } else {
            item.children = [];
            item.children.push({
              t: tagId,
              id: tagId,
              title: tagName,
              index: item.children.length + "," + item.index,
            });
            item.menu = item.children;
          }
        }
      }
      this.setState({
        treeData,
        onSubmit: true,
      });
    }
  }

  fetchNavData = () => {
    this.menuapi = new API({ url: "/website-service/nav" });
    this.menuapi
    .get({},this.headers)
      .then((res) => {
        const menu = res.data || [];
        if (menu && menu.length > 0) {
          let generateTree = generateSubMenu(menu);
          this.setState({
            loading: false,
            treeData: generateTree,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        } else {
          this.setState({
            error: error.message,
            loading: false,
          });
        }
      });
  };

  componentDidMount() {
    this.fetchNavData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.onSubmit && this.state.onSubmit !== prevState.onSubmit) {
      const tree = JSON.parse(JSON.stringify(this.state.treeData));
      let data = generateSubMenu(tree, true);
      this.api = new API({ url: "/website-service/nav" });
        this.api.post(
          { menu: data }, 
          this.headers
        ).then(
          (response) => {
            this.setState({
              success: true,
              onSubmit: false,
            });
          },
          (error) => {
            if (error.code === 401 || error.code === 403) {
              throw error;
            } else {
              this.setState({
                error: error.message,
                onSubmit: false,
              });
            }
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
  }
  componentWillUnmount() {
    this.api && this.api.cancel();
    this.menuapi && this.menuapi.cancel();
  }

  handleResetCatagories = () => {
    this.api = new API({ url: "/website-service/nav" });
    const data = { menu: [] };
    this.api
      .post({ data }, this.headers)
      .then((response) => {
        this.fetchNavData();
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        } else {
          this.setState({
            error: error.message,
            loading: false,
          });
        }
      });
  };

  render() {
    const { loading, treeData, error, showPopup, val } = this.state;
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        className="category-tree category-sequence"
        showLanguageDropDown
      >
        <div className="heading">
          <h1>{getMessage("navigation.sequencing.heading")}</h1>
          <div className="header-add-reset-buttons">
            <button className="button" onClick={this.handleResetCatagories}>
              Reset
            </button>
            {
              <DropDown
                icon={
                  <button className="primary button">
                    {getMessage("navigation.add.button")}
                  </button>
                }
              >
                <DropDownItem
                  onClick={() => {
                    this.onAppendClick("Tag");
                  }}
                >
                  {getMessage("navigation.dropdown.item.addTag")}
                </DropDownItem>
                <DropDownItem
                  onClick={() => {
                    this.onAppendClick("Link");
                  }}
                >
                  {getMessage("navigation.dropdown.item.addLink")}
                </DropDownItem>
              </DropDown>
            }
          </div>
        </div>
        <Popup
          show={showPopup}
          close={this.hidePopup}
          heading={`${getMessage("category.add")} ${val}`}
        >
          <AddForm val={val} onSubmit={this.onConfirmClick} />
        </Popup>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {error && <div className="form-error">{error}</div>}
            {treeData.length > 0 ? (
              <Tree
                data={treeData}
                className="category-tree"
                onDragStateChanged={({ isDragging }) => {
                  if (isDragging) {
                    this.treeData = this.state.treeData;
                  }
                }}
                getNodeKey={({ node }) => node.index}
                onVisibilityToggle={() => {
                  this.setState({
                    onSubmit: false,
                  });
                }}
                onChange={(e) => this.setState({ treeData: e, onSubmit: true })}
                canDrop={({ node, nextParent, prevParent }) => {
                  const nextParentId = nextParent && nextParent.id;
                  const prevParentId = prevParent && prevParent.id;
                  if (node.hasOwnProperty("c")) {
                    if (nextParentId === prevParentId) {
                      return true;
                    }
                    return false;
                  }
                  return true;
                }}
                generateNodeProps={({ node, path }) => ({
                  buttons: [
                    node.hasOwnProperty("c") ? null : node.hasOwnProperty(
                        "t"
                      ) ? (
                      <Image src={TagIcon} />
                    ) : (
                      <Image src={LinkIcon} />
                    ),
                    <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
                      <DropDownItem
                        onClick={() => {
                          this.showPopup(path, "Tag");
                        }}
                      >
                        {getMessage("navigation.dropdown.item.addTag")}
                      </DropDownItem>
                      <DropDownItem
                        onClick={() => {
                          this.showPopup(path, "Link");
                        }}
                      >
                        {getMessage("navigation.dropdown.item.addLink")}
                      </DropDownItem>
                    </DropDown>,
                    !node.hasOwnProperty("c") ? (
                      <button
                        className="remove-button"
                        onClick={() => this.removeItem(path)}
                      >
                        Delete Category
                      </button>
                    ) : null,
                  ],
                })}
              />
            ) : (
              <p className="no-items">
                {getMessage("navigation.nosequence.display")}
              </p>
            )}
          </React.Fragment>
        )}
      </AuthenticatedPage>
    );
  }
}

export default CategorySequencing;
