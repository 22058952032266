import React from "react";
import {
  BaseForm,
  Input,
  Upload,
  Searchable,
  VALIDATION_TYPES,
  Textarea,
  Radio,
  Select,
} from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
import "./style.css";
import { Helmet } from "react-helmet";
import { get } from "../../../../lib/storage";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const SOCIAL_MEDIA = [
  { text: "Snapchat", value: "snapchat" },
  { text: "Tiktok", value: "tiktok" },
  { text: "Instagram", value: "instagram" },
  { text: "Youtube", value: "youtube" },
  { text: "Facebook", value: "facebook" },
  { text: "Twitter", value: "twitter" },
  { text: "Pinterest", value: "pinterest" },
  { text: "Whatsapp", value: "whatsapp" },
];

const DragHandle = SortableHandle(() => (
  <span className="sortable-handle">⠿</span>
));

const SortableItem = SortableElement(({ deleteKey, renderInputAndSelect }) => {
  return (
    <div className="key-container">
      <DragHandle />
      <button
        type="button"
        className="delete-button"
        onClick={() => deleteKey()}
      >
        {getMessage("delete")}
      </button>
      {renderInputAndSelect()}
    </div>
  );
});

const SortableList = SortableContainer(
  ({ items, deleteKey, renderInputAndSelect, selectedTypes }) => {
    return (
      <div className="sortable-list">
        {items?.map((platform, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              deleteKey={() => deleteKey(index)}
              renderInputAndSelect={() =>
                renderInputAndSelect(index, selectedTypes)
              }
            />
          );
        })}
      </div>
    );
  }
);

export default class InfluencerForm extends BaseForm {
  constructor(props) {
    super(props);
    this.direction = get("dataLang") === "ar" ? "rtl" : "ltr";
  }

  addKey = () => {
    this.setState((prevState) => ({
      values: {
        ...prevState?.values,
        metaData: [
          ...(prevState?.values?.metaData || []),
          { socialMediaType: "", socialMediaId: "" },
        ],
      },
    }));
  };

  deleteKey = (index) => {
    this.setState((prevState) => {
      const updatedValuesMetaData = [...(prevState?.values?.metaData || [])];
      const deletedType = updatedValuesMetaData[index]?.socialMediaType;
      updatedValuesMetaData.splice(index, 1);
      const updatedDefault =
        prevState.values?.default === deletedType
          ? null
          : prevState.values?.default;

      return {
        values: {
          ...prevState?.values,
          metaData: updatedValuesMetaData,
          default: updatedDefault,
        },
      };
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState((prevState) => {
      const updatedMetaData = arrayMove(
        prevState?.values?.metaData || [],
        oldIndex,
        newIndex
      );
      return {
        values: {
          ...prevState?.values,
          metaData: updatedMetaData,
        },
      };
    });
  };

  renderInputAndSelect = (index, selectedTypes) => {
    const selectedTypeOptions =
      this.state.values?.metaData?.[index]?.socialMediaType;

    const availableOptions = SOCIAL_MEDIA?.filter(
      ({ value }) =>
        !selectedTypes?.includes(value) || value === selectedTypeOptions
    );

    return (
      <>
        <Select
          name={`socialMediaType_${index}`}
          placeholder={getMessage(
            "marketing.influencer.select.socialmedia.placeholder"
          )}
          label={getMessage("marketing.influencer.socialmedia.type")}
          options={availableOptions}
          {...this.generateStateMappers({
            stateKeys: ["metaData", index, "socialMediaType"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
        <Input
          type="text"
          name={`socialMediaId_${index}`}
          placeholder={getMessage(
            "marketing.influencer.select.socialmediaId.placeholder"
          )}
          label={getMessage("marketing.influencer.socialmedia.id")}
          {...this.generateStateMappers({
            stateKeys: ["metaData", index, "socialMediaId"],
            validationType: VALIDATION_TYPES.ONSUBMIT,
            loseEmphasisOnFill: true,
          })}
        />
      </>
    );
  };

  render() {
    const { CancelButton, SubmitButton } = this.buttons;
    const { Form } = this.components;

    const selectedTypes = (this.state.values?.metaData || [])?.map(
      (item) => item.socialMediaType
    );
    const availableRadioOptions = SOCIAL_MEDIA?.filter(({ value }) =>
      selectedTypes?.includes(value)
    );
    const remainingSocialMediaOptions = SOCIAL_MEDIA?.filter(
      ({ value }) => !selectedTypes?.includes(value)
    );

    return (
      <div>
        <Helmet title="Zopping - Add Influencer" />
        <Form className="influencer-form">
          <Input
            label={getMessage("marketing.influencer.form.title.heading")}
            placeholder={getMessage(
              "marketing.influencer.form.title.placeholder"
            )}
            name="name"
            type="text"
            required
            {...this.generateStateMappers({
              stateKeys: ["name"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            validationStrings={{
              valueMissing: getMessage(
                "marketing.influencer.form.title.requiredMessage"
              ),
            }}
            dir={this.direction}
          />
          <div className="form-section">
            <SortableList
              items={this.state.values?.metaData ?? []}
              deleteKey={this.deleteKey}
              renderInputAndSelect={this.renderInputAndSelect}
              onSortEnd={this.onSortEnd}
              useDragHandle
              axis="xy"
              helperClass="dragging-item"
              selectedTypes={selectedTypes}
            />
            {remainingSocialMediaOptions.length > 0 && (
              <div className="add-button">
                <button type="button" className="button" onClick={this.addKey}>
                  {getMessage("marketing.influencer.form.add.socialmedia")}
                </button>
              </div>
            )}
          </div>
          {availableRadioOptions?.length > 0 && (
            <Radio
              label={getMessage("marketing.influencer.form.default")}
              name="default"
              options={availableRadioOptions}
              {...this.generateStateMappers({
                stateKeys: ["default"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Textarea
            label={getMessage(
              "marketing.influencer.form.description.title.heading"
            )}
            placeholder={getMessage(
              "marketing.influencer.form.description.title.placeholder"
            )}
            name="description"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["description"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            dir={this.direction}
          />
          <Upload
            label={getMessage(
              "marketing.recipe.form.featured.image.title.heading"
            )}
            placeholder={getMessage("brand.form.image.placeholder")}
            {...this.generateStateMappers({
              stateKeys: ["image"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <Searchable
            label={getMessage("marketing.influencer.form.recipe.title.heading")}
            placeholder={getMessage(
              "marketing.influencer.form.recipe.add.placeholder"
            )}
            name="Recipe"
            searchUrl="/recipe-service/recipe"
            valueKey="id"
            responseKey="tag"
            nameKey="name"
            searchKey="name"
            dir={this.direction}
            transformResponse={(response) => response?.data?.recipes}
            transformRequest={(searchText, paramsDefault) => {
              paramsDefault["name"] = `%${paramsDefault?.name}%`;
              return paramsDefault;
            }}
            multiple
            {...this.generateStateMappers({
              stateKeys: ["recipes"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
          />
          <div className="form-buttons">
            <SubmitButton>{getMessage("save.text")}</SubmitButton>
            <CancelButton>{getMessage("cancel.text")}</CancelButton>
          </div>
        </Form>
      </div>
    );
  }
}
