// eslint-disable-next-line
import React from "react";
import Form, { CheckboxGroup } from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";

export default function LayoutVisibility({
  getState,
  updateState,
  className,
  ...props
}) {
  return (
    <>
      <div className={"layout-visibility " + (className ? className : "")}>
        <div>
          <Form>
            <CheckboxGroup
              name="visibleOn"
              label={getMessage("themes.layout.visible.text")}
              options={[
                {
                  text: getMessage("layout.visible.DESKTOP_WEB"),
                  value: "DESKTOP_WEB",
                },
                {
                  text: getMessage("layout.visible.MOBILE_WEB"),
                  value: "MOBILE_WEB",
                },
                {
                  text: getMessage("layout.visible.ANDROID"),
                  value: "ANDROID",
                },
                {
                  text: getMessage("layout.visible.IOS"),
                  value: "IOS",
                },
              ]}
              value={getState(["allowedPlatforms"])}
              onChange={(value) => {
                updateState(["allowedPlatforms"], value);
              }}
              className={""}
            />
          </Form>
        </div>
        <div>{props.children}</div>
      </div>
    </>
  );
}
